<template>
  <b-card>
    <title-name />
    <rate-value />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import TitleName from '@/views/Admin/Defines/Taxes/elements/Title.vue'
import RateValue from '@/views/Admin/Defines/Taxes/elements/Rate.vue'

export default {
  name: 'TaxForm',
  components: {
    BCard,
    TitleName,
    RateValue,
  },
}
</script>
